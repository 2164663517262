import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltersFacade } from '@modules/filters/facades';
import { defaultBuildingFormValue, intervalDay } from '@modules/filters/constants';
import { FilterTypeEnum } from '@modules/filters/enums';
import { BuildingsFacade } from '@modules/buildings/facades/buildings-facade.service';
import { datePickerDateFormat } from '@shared/constants';

@Component({
  selector: 'hm-filters-panel-building',
  templateUrl: './filters-panel-building.component.html',
  styleUrls: ['./filters-panel-building.component.scss']
})
export class FiltersPanelBuildingComponent {
  @ViewChild('form') form!: NgForm;

  constructor(
    private readonly buildingsFacade: BuildingsFacade,
    private readonly filtersFacade: FiltersFacade,
  ) {
  }

  public readonly vm$ = combineLatest([
    this.buildingsFacade.buildingsData$,
    this.filtersFacade.getFilterFormValueByType(FilterTypeEnum.BuildingUtilization)
  ]).pipe(
    map(([buildingsData, filterValue]) => ({
      buildingsData,
      filterValue
    }))
  );
  public readonly datePickerDateFormat = datePickerDateFormat;
  public readonly today = new Date();
  public readonly defaultFormValue = defaultBuildingFormValue;

  selectBuilding(): void {
    this.form.controls.dateRange.reset();
    this.resetHoursType();
  }

  selectDateRange(): void {
    this.resetHoursType();
  }

  submit(form: NgForm): void {
    const filter = this.filtersFacade.convertFormValueToModel(FilterTypeEnum.BuildingUtilization, {
      ...form.value,
      interval: intervalDay
    });

    this.filtersFacade.applyFilter(filter);
  }

  private resetHoursType() {
    this.form.controls.hoursType.reset(defaultBuildingFormValue.hoursType);
  }
}
