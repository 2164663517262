import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { IntervalEnum } from '@modules/filters/enums';
import { getEndOf, getStartOf } from '@shared/utils/date-utils';

const errorMessage = {
  [IntervalEnum.Week]: {
    startDate: 'Please select the first date of this week (Sunday)',
    endDate: 'Please select the last date of this week (Saturday)',
  },
  [IntervalEnum.Month]: {
    startDate: 'Please select the first date of the month',
    endDate: 'Please select the last date of the month',
  }
};

@Directive({
  selector: '[hmDateRangeInterval]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateRangeIntervalDirective,
      multi: true,
    },
  ]
})
export class DateRangeIntervalDirective implements Validator {
  @Input() hmDateRangeInterval!: IntervalEnum.Week | IntervalEnum.Month;

  validate(control: AbstractControl<[Date, Date]>): ValidationErrors | null {
    if (!this.hmDateRangeInterval || ![IntervalEnum.Week, IntervalEnum.Month].includes(this.hmDateRangeInterval)) {
      return null;
    }

    const [startDate, endDate] = control.value ?? [];
    if (!startDate || !endDate) {
      return null;
    }

    const unitOfTime = this.hmDateRangeInterval === IntervalEnum.Month ? 'months' : 'weeks';

    const startOf = getStartOf(startDate, unitOfTime);
    const startDateDay = startDate.getDay();

    if (startDateDay !== startOf) {
      return { error: { value: startDate, message: errorMessage[this.hmDateRangeInterval].startDate } };
    }

    const endOf = getEndOf(endDate, unitOfTime);
    const endDateDay = endDate.getDay();

    if (endDateDay !== endOf) {
      return { error: { value: endDate, message: errorMessage[this.hmDateRangeInterval].endDate } };
    }

    return null;
  }

}
