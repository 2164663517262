import { dateTimeFormat } from '@shared/constants';

import dayjs, { ConfigType, OpUnitType, QUnitType } from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import advancedFormat from 'dayjs/esm/plugin/advancedFormat';
import updateLocale from 'dayjs/esm/plugin/updateLocale';

dayjs.extend(utc);
dayjs.extend(relativeTime, {
  thresholds: 
    [
      { l: 's', r: 59, d: 'second' },
      { l: 'm', r: 89 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 89 },
      { l: 'hh', r: 21, d: 'hour' },
      { l: 'd', r: 35 },
      { l: 'dd', r: 25, d: 'day' },
      { l: 'M', r: 45 },
      { l: 'MM', r: 10, d: 'month' },
      { l: 'y', r: 17 },
      { l: 'yy', d: 'year' }
    ] ,
    rounding: Math.floor
});

dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);

export const MS_IN_SEC = 1000;
export const MS_IN_MIN = MS_IN_SEC * 60;
export const MS_IN_HOUR = MS_IN_MIN * 60;
export const MS_IN_DAY = MS_IN_HOUR * 24;
export const MS_IN_WEEK = MS_IN_DAY * 7;

export function formatToServerDateString(date: Date): string {
  return dayjs(date)
    .utc()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .format(dateTimeFormat);
}

export function getTimeFromNow(utcDateTimeString: string): string {
  const local = parseUtc(utcDateTimeString);
  const daysDiffFromNow = getTimeDifferenceFromNow(local, 'days');

  if (daysDiffFromNow > 2) {
    return formatDate(local, 'MM/DD/YYYY');
  }

  if (!dayjs(local).isValid()) {
    return 'Invalid Date';
  }
  return dayjs(local).fromNow();
}

export function getTimeDifferenceFromNow(date: Date, unitOfTime: QUnitType | OpUnitType) {
  const now = dayjs().local();
  return now.diff(dayjs(date), unitOfTime);
}


export function parseUtc(utcDateTimeString: string): Date {
  return dayjs.utc(utcDateTimeString).toDate();
}

export function formatDate(date: Date, format: string): string {
  return dayjs(date).format(format);
}


export function formatDateString(date: string, format: string): string {
  return formatUTCDate(date, format);
}

export function formatDateTime(dateTime: number, format: string): string {
  return formatUTCDate(dateTime, format);
}

export function formatUTCDate(utcInput: ConfigType, format: string) {
  return dayjs.utc(utcInput).format(format);
}

export function formatDateRange(startDate: string, endDate: string): string {
  const s = dayjs.utc(startDate);
  const e = dayjs.utc(endDate);

  return `${s.format(`MM/DD, hh:mm A`)} - ${e.format(`MM/DD, hh:mm A`)}`;
}

export function setDateTime(dateString: string, setObject: Partial<Record<dayjs.UnitType, number>> = {}, format = dateTimeFormat): string {
  let date = dayjs.utc(dateString);

  for (const [unit, value] of Object.entries(setObject)) {
    date = date.set(unit as dayjs.UnitType, value as number);
  }

  return date.format(format);
}


export function addDurationMsUTC(dateString: string, ms: number, format = dateTimeFormat): string {
  return dayjs.utc(dateString).add(ms).format(format);
}

export function getDatesDiff(startDate: string, endDate: string, unitOfTime: QUnitType | OpUnitType): number {
  return dayjs.utc(endDate).diff(dayjs.utc(startDate), unitOfTime);
}

export function getDaysInMonth(date: string): number {
  return dayjs.utc(date).daysInMonth();
}

export function getStartOf(date: Date, startOf: OpUnitType): number {
  return dayjs(date).startOf(startOf).get('day');
}

export function getEndOf(date: Date, endOf: OpUnitType): number {
  return dayjs(date).endOf(endOf).get('day');
}

export function subtractDurationMsUTC(dateString: string, ms: number, format = dateTimeFormat): string {
  return dayjs.utc(dateString).subtract(ms).format(format);
}

type TimeKeys = 'hours' | 'minutes' | 'seconds';

export function checkTimeUTC(dateTimeString: string, time: Pick<Partial<Record<TimeKeys, number>>, TimeKeys>): boolean {
  const date = dayjs.utc(dateTimeString);

  return Object.entries(time).every(([timeKey, value]) => {
    return value === date.get(timeKey as dayjs.UnitType);
  });
}

export class DateRange {
  constructor(
    public startDate: string,
    public endDate: string,
  ) {}

  static from(startDate: string, endDate: string, boundaries?: {
    start?: Partial<Record<dayjs.UnitType, number>>;
    end?: Partial<Record<dayjs.UnitType, number>>;
  }): DateRange {
    if (!boundaries) {
      return new DateRange(startDate, endDate);
    }

    if (boundaries?.start) {
      startDate = setDateTime(startDate, boundaries.start);
    }

    if (boundaries?.end) {
      endDate = setDateTime(endDate, boundaries.end);
    }

    return new DateRange(startDate, endDate);
  }

  equals(dateRange: DateRange): boolean {
    return this.startDate === dateRange.startDate && this.endDate === dateRange.endDate;
  }

  within(dateRange: DateRange): boolean {
    const thisStartDate = dayjs.utc(this.startDate);
    const thisEndDate = dayjs.utc(this.endDate);
    const outStartDate = dayjs.utc(dateRange.startDate);
    const outEndDate = dayjs.utc(dateRange.endDate);

    return !(
      thisStartDate.isBefore(outStartDate) || thisStartDate.isAfter(outEndDate)
    ) && !(
      thisEndDate.isBefore(outStartDate) || thisEndDate.isAfter(outEndDate)
    );
  }

  clone(boundaries?: { start?: Partial<Record<dayjs.UnitType, number>>; end?: Partial<Record<dayjs.UnitType, number>> }): DateRange {
    return DateRange.from(this.startDate, this.endDate, boundaries);
  }

  toString(): string {
    return `${this.startDate} - ${this.endDate}`;
  }
}


