<hm-modal>
  <form #form="ngForm" (ngSubmit)="submit(form)" *ngIf="vm$ | async as vm" class="filters-panel__container">
    <hm-filters-panel-header headerTitle="Office utilization"></hm-filters-panel-header>

    <main class="filters-panel__body">
      <hm-search
        #buildingControl="ngModel"
        (ngModelChange)="selectBuilding()"
        [(ngModel)]="vm.filterValue.building"
        [isRequired]="true"
        [optionTemplate]="buildingTemplate"
        [options]="vm.buildingsData.buildings"
        label="Office"
        name="building"
        optionLabelField="name"
        placeholder="Select office"
        required>
      </hm-search>

      <dds-daterangepicker
        #dateRangeControl="ngModel"
        (ngModelChange)="selectDateRange()"
        [(ngModel)]="vm.filterValue.dateRange"
        [disabled]="!buildingControl.value"
        [errorMessage]="dateRangeControl?.errors?.error?.message || dateRangeControl?.errors?.maxDays"
        [format]="datePickerDateFormat"
        [hmDateRangeInterval]="vm.filterValue.interval.value"
        [isManualInput]="true"
        [isRequired]="true"
        [isUTCFormat]="true"
        [maxDate]="today"
        [placeholder]="['Start date', 'End date']"
        stickerPosition="right"
        class="filters-panel__body-item"
        label="Date range"
        labelPosition="external"
        name="dateRange"
        required
        size="md">
      </dds-daterangepicker>

      <hm-select-list
        [(ngModel)]="vm.filterValue.hoursType"
        [isRequired]="true"
        [options]="vm.buildingsData.hoursTypes"
        label="Hours type"
        name="hoursType"
        optionLabelField="label"
        placeholder="Select hours type"
        required>
      </hm-select-list>

      <hm-radio-list
        [(ngModel)]="vm.filterValue.device"
        [isRequired]="true"
        [options]="vm.buildingsData.devices"
        name="device"
        optionLabelField="label"
        orientation="vertical"
        required
        title="Devices">
      </hm-radio-list>
    </main>

    <hm-filters-panel-actions [defaultFormValue]="defaultFormValue"></hm-filters-panel-actions>
  </form>
</hm-modal>

<ng-template #buildingTemplate let-facility>
  {{ facility.name }}
</ng-template>
