import { Component, OnInit } from '@angular/core';
import { FiltersFacade } from '@modules/filters/facades';
import { Filter } from '@modules/filters/models';
import { ModalService } from '@core/services';
import { RemoveRecentSelectionDialogComponent } from '../remove-recent-selection-dialog/remove-recent-selection-dialog.component';

@Component({
  selector: 'hm-filters-card-list',
  templateUrl: './filters-card-list.component.html',
  styleUrls: ['./filters-card-list.component.scss']
})
export class FiltersCardListComponent implements OnInit {
  filters$ = this.filtersFacade.filters$;

  constructor(
    private filtersFacade: FiltersFacade,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.filtersFacade.loadFilters();
  }

  selectFilter(filter: Filter): void {
    this.filtersFacade.applyFilter(filter);
  }

  removeFilter(filter: Filter): void {
    this.modalService.open(RemoveRecentSelectionDialogComponent, { filter });
  }
}
