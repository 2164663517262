import { Component, Input } from '@angular/core';
import { filterTypeLabel } from '@modules/filters/constants';
import { Filter } from '@modules/filters/models';
import { ModalService } from '@core/services';
import { FiltersFacade } from '@modules/filters/facades';
import { datePickerDateFormat } from '@shared/constants';

@Component({
  selector: 'hm-remove-recent-selection-dialog',
  templateUrl: './remove-recent-selection-dialog.component.html',
  styleUrls: ['./remove-recent-selection-dialog.component.scss']
})
export class RemoveRecentSelectionDialogComponent {
  @Input() filter!: Filter;

  constructor(
    private readonly modalService: ModalService,
    private readonly filtersFacade: FiltersFacade,
  ) {
  }

  public readonly datePickerDateFormat = datePickerDateFormat;
  public readonly filterTypeLabel = filterTypeLabel;

  close() {
    this.modalService.close();
  }

  remove() {
    this.filtersFacade.removeFilter(this.filter);
    this.modalService.close();
  }
}
