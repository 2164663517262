import { Subscription } from 'rxjs';

export default class DestroyNotifier {
  private subscriptionsHolder = new Subscription();

  public set sink(subscription: Subscription) {
    this.subscriptionsHolder.add(subscription);
  }

  public unsubscribe(): void {
    this.subscriptionsHolder.unsubscribe();
    this.subscriptionsHolder = new Subscription();
  }
}
