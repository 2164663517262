<dds-radio-group
  (stateChanged)="onValueChanged($event)"
  [isDisabled]="isDisabled"
  [isRequired]="isRequired"
  [name]="name"
  [title]="title"
  [value]="value">
  <ng-container *ngFor="let option of options">
    <dds-radio
      [label]="option[optionLabelField]"
      [theme]="theme"
      [value]="optionValueField ? option[optionValueField] : option"
      class="radio-list__option">
    </dds-radio>
    <br *ngIf="orientation === 'vertical'">
  </ng-container>
</dds-radio-group>
