import { EnumValue } from '../models';
import { IntervalEnum } from '../enums';
import { intervalLabel } from './interval-label';

export const intervalTenMinutes = {
  value: IntervalEnum.TenMinutes,
  label: intervalLabel[IntervalEnum.TenMinutes],
  isDefault: true
};

export const intervalDay = {
  value: IntervalEnum.Day,
  label: intervalLabel[IntervalEnum.Day],
  isDefault: false
}

export const intervalsList: EnumValue[] = [
  intervalTenMinutes,
  {
    value: IntervalEnum.Hour,
    label: intervalLabel[IntervalEnum.Hour],
    isDefault: false
  },
  intervalDay,
  {
    value: IntervalEnum.Week,
    label: intervalLabel[IntervalEnum.Week],
    isDefault: false
  },
  {
    value: IntervalEnum.Month,
    label: intervalLabel[IntervalEnum.Month],
    isDefault: false
  }
];
