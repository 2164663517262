import { AfterViewInit, Directive, ElementRef, EventEmitter, inject, Output } from '@angular/core';

@Directive({
  selector: '[hmElementSize]',
  exportAs: 'hmElementSize',
  standalone: true,
})
export class ElementSizeDirective implements AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('ready') ready$ = new EventEmitter<DOMRect>();

  private readonly elementRef = inject<ElementRef<Element>>(ElementRef);

  get rect(): DOMRect {
    return this.elementRef.nativeElement?.getBoundingClientRect();
  }

  get width(): number {
    const rect = this.elementRef.nativeElement?.getBoundingClientRect();

    return rect?.width;
  }

  get height(): number {
    const rect = this.elementRef.nativeElement?.getBoundingClientRect();

    return rect?.height;
  }

  get bottom(): number {
    const rect = this.elementRef.nativeElement?.getBoundingClientRect();

    return rect?.bottom;
  }

  async ngAfterViewInit() {
    await Promise.resolve();

    const rect = this.elementRef.nativeElement.getBoundingClientRect();

    this.ready$.emit(rect);
  }
}
