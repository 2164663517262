import { Component } from '@angular/core';

@Component({
  selector: 'hm-icon-sticker',
  templateUrl: './icon-sticker.component.html',
  styleUrls: ['./icon-sticker.component.scss']
})
export class IconStickerComponent {

}
