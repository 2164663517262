import { Injectable } from '@angular/core';
import { ToastOptions, ToastService as DDSToastService } from '@usitsdasdesign/dds-ng/toast';

const defaultToastOptions: Partial<ToastOptions> = {
  lifeTime: 2000,
  position: 'bottom-center',
  isCloseIcon: false,
  limit: 5,
};

const successToastOptions: Partial<ToastOptions> = {
  ...defaultToastOptions,
  theme: 'green'
};

const errorToastOptions: Partial<ToastOptions> = {
  ...defaultToastOptions,
  theme: 'danger',
  isError: true,
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastService: DDSToastService) {
  }

  create(options: ToastOptions): void {
    this.toastService.createToast(options);
  }

  success(options: Partial<ToastOptions>): void {
    this.create({
      ...successToastOptions,
      ...options,
    });
  }

  error(options: Partial<ToastOptions>): void {
    this.create({
      ...errorToastOptions,
      ...options,
    });
  }

  closeAll() {
    this.toastService.closeAll();
  }
}
