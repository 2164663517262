import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http';
import { FloorPlanSizeDto } from 'src/app/modules/filters/dtos';
import { HttpParams } from '@angular/common/http';
import { formatDateString } from 'src/app/shared/utils/date-utils';
import { BuildingsDataDto, FloorDto } from '@modules/buildings/dtos';
import { FloorInfoDto } from '@modules/filters/dtos/floor-info.dto';

@Injectable({ providedIn: 'root' })
export class BuildingsService {

  constructor(private readonly api: ApiService) { }

  fetchBuildingsData(): Observable<BuildingsDataDto> {
    return this.api.get('/buildings/buildingsData');
  }

  fetchFloorImage(revision: string): Observable<Blob> {
    return this.api.getBlob(`/floorPlans/${revision}/image`);
  }

  fetchFloorInfo(floor: FloorDto, endDate: string): Observable<FloorInfoDto> {
    const queryParams = new HttpParams()
      .set('EndTime', formatDateString(endDate, 'YYYY-MM-DD'));

    return this.api.get(`/floors/${floor.floorId}/info`, queryParams);
  }
}
