import { Injectable, NgZone, Type } from '@angular/core';
import { ModalService as ModalServiceDDS } from '@usitsdasdesign/dds-ng/modal';
import { ModalRef } from '@usitsdasdesign/dds-ng/modal/modal-ref.class';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalRef: ModalRef | null = null;

  constructor(
    private readonly modalService: ModalServiceDDS,
    private readonly ngZone: NgZone
  ) {
  }

  open<T>(component: Type<T>, inputs?: { [key: string]: unknown; }, enableEscForClosing?: boolean, onCloseFn?: () => void) {
    if (this.modalRef) {
      this.closeAll();
    }

    this.modalRef = this.modalService.open(component, inputs, enableEscForClosing);

    this.modalRef.onClosed()
      .pipe(
        first()
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          onCloseFn?.();
        });
        this.modalRef = null;
      });

    return this.modalRef;
  }

  close(data?: any): void {
    this.modalService.close(data);
    this.modalRef = null;
  }

  closeAll(): void {
    this.modalService.closeAll();
  }
}
