import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  IsActiveMatchOptions,
  NavigationEnd,
  Params,
  QueryParamsHandling,
  Router,
  UrlTree
} from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[hmHighlightRouterLink]',
  exportAs: 'hmHighlightRouterLink',
})
export class HighlightRouterLinkDirective implements OnChanges, OnDestroy, AfterContentInit {
  private classes: string[] = [];
  private routerEventsSubscription: Subscription;
  private linkInputChangesSubscription?: Subscription;
  private _isActive = false;
  private commands: any[] | null = null;

  @Input()
  set hmHighlightRouterLink(commands: any[] | string | null | undefined) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
    } else {
      this.commands = null;
    }
  }

  @Input() routerLinkActiveOptions: IsActiveMatchOptions = {
    paths: 'subset',
    queryParams: 'subset',
    fragment: 'ignored',
    matrixParams: 'ignored'
  };
  @Input() ariaCurrentWhenActive?: 'page' | 'step' | 'location' | 'date' | 'time' | true | false;

  @Input() queryParams?: Params | null;
  @Input() fragment?: string;
  @Input() queryParamsHandling?: QueryParamsHandling | null;
  @Input() state?: { [k: string]: any };
  @Input() info?: unknown;
  @Input() relativeTo?: ActivatedRoute | null;
  @Input() preserveFragment = false;

  @Output() readonly isActiveChange: EventEmitter<boolean> = new EventEmitter();

  get urlTree(): UrlTree | null {
    if (this.commands === null) {
      return null;
    }
    return this.router.createUrlTree(this.commands, {
      // If the `relativeTo` input is not defined, we want to use `this.route` by default.
      // Otherwise, we should use the value provided by the user in the input.
      relativeTo: this.relativeTo !== undefined ? this.relativeTo : this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: this.preserveFragment,
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    private renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.routerEventsSubscription = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  ngAfterContentInit(): void {
    this.update();
  }

  @Input()
  set routerLinkActive(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter((c) => !!c);
  }

  ngOnChanges(): void {
    this.update();
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe();
    this.linkInputChangesSubscription?.unsubscribe();
  }

  private update(): void {
    if (!this.router.navigated) return;

    queueMicrotask(() => {
      const hasActiveLinks = this.hasActiveLinks();

      this.classes.forEach((c) => {
        if (hasActiveLinks) {
          this.renderer.addClass(this.element.nativeElement, c);
        } else {
          this.renderer.removeClass(this.element.nativeElement, c);
        }
      });

      if (hasActiveLinks && this.ariaCurrentWhenActive !== undefined) {
        this.renderer.setAttribute(
          this.element.nativeElement,
          'aria-current',
          this.ariaCurrentWhenActive.toString(),
        );
      } else {
        this.renderer.removeAttribute(this.element.nativeElement, 'aria-current');
      }

      // Only emit change if the active state changed.
      if (this._isActive !== hasActiveLinks) {
        this._isActive = hasActiveLinks;
        this.cdr.markForCheck();
        // Emit on isActiveChange after classes are updated
        this.isActiveChange.emit(hasActiveLinks);
      }
    });
  }

  private isLinkActive(router: Router): (urlTree?: UrlTree) => boolean {
    const options: IsActiveMatchOptions = this.routerLinkActiveOptions;

    return (urlTree?: UrlTree) => {
      return urlTree
        ? router.isActive(urlTree, options)
        : false;
    };
  }

  private hasActiveLinks(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    const urlTree = this.urlTree;

    return !!(urlTree && isActiveCheckFn(urlTree));
  }
}
