import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@core/services';

@Component({
  selector: 'hm-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent {
  @Input() dialogTitle!: string;

  modalService = inject(ModalService);

  close(): void {
    this.modalService.close();
  }
}
