<div class="error-page__content">
  <ng-container [ngSwitch]="statusCode">
    <ng-container *ngSwitchCase="'403'">
      <img alt="Access denied" class="error-page__image" src="./assets/images/error-403.svg">
    </ng-container>
    <ng-container *ngSwitchCase="'404'">
      <img alt="Page not found" class="error-page__image" src="./assets/images/error-404.svg">
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img alt="Something went wrong" class="error-page__image" src="./assets/images/error-something-went-wrong.svg">
    </ng-container>
  </ng-container>

  <span *ngIf="!!title" class="dds-h5">{{ title }}</span>
  <span class="error-page__description dds-body">{{ description }}</span>

  <dds-button (click)="navigateBack()" *ngIf="buttonLabel">
    {{ buttonLabel }}
  </dds-button>
</div>

