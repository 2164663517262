<div class="dds-modal-overlay" (click)="close()"></div>

<section class="dds-modal confirm-dialog__container">
  <header class="confirm-dialog__header">
    <span class="dds-h5">{{ dialogTitle }}</span>
    <dds-button theme="dark" icon="dds-icon_close" kind="silent" (click)="close()"></dds-button>
  </header>

  <main class="confirm-dialog__body dds-body">
    <ng-content select=".body"></ng-content>
  </main>

  <footer class="confirm-dialog__footer">
    <ng-content select=".footer"></ng-content>
  </footer>
</section>
