import { EnumValue } from '../models';
import { TechnologyEnum } from '../enums';
import { technologyLabel } from './technology-label';

export const technologiesList: EnumValue[] = [
  {
    value: TechnologyEnum.WiFi,
    label: technologyLabel[TechnologyEnum.WiFi],
  },
  {
    value: TechnologyEnum.Sensors,
    label: technologyLabel[TechnologyEnum.Sensors],
  },
];
