import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { FilterDto } from '@modules/filters/dtos';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(private api: ApiService) { }

  fetchFilters(take = 10) {
    return this.api.get<FilterDto[]>(`/filters?Take=${take}`);
  }

  saveFilter(filter: FilterDto) {
    return this.api.put<FilterDto>(`/filters`, filter);
  }

  removeFilter(filterId: number) {
    return this.api.delete(`/filters/${filterId}`);
  }
}
