import { Directive, OnDestroy } from '@angular/core';
import DestroyNotifier from '../classes/DestroyNotifier';

@Directive({
  selector: '[hmDestroyNotifier]',
  standalone: true
})
export class DestroyNotifierDirective extends DestroyNotifier implements OnDestroy {
  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
