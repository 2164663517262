import { Component, inject } from '@angular/core';
import { User, UsersFacade } from '@core/users';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'hm-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent {
  private readonly usersFacade = inject(UsersFacade);

  public readonly currentUser$ = this.usersFacade.currentUser$.pipe(
    tap(user => this.buildInitials(user))
  );

  public initials = '';

  logout() {
    this.usersFacade.logout();
  }

  private buildInitials(user: User): void {
    this.initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
  }
}
