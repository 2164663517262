import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FiltersFacade } from '@modules/filters/facades';
import { defaultFloorFormValue } from '@modules/filters/constants';
import { FilterTypeEnum, IntervalEnum } from '@modules/filters/enums';
import { BuildingsFacade } from '@modules/buildings/facades/buildings-facade.service';
import { Building, Floor } from '@modules/buildings/models';
import { datePickerDateFormat } from '@shared/constants';
import { getDatesDiff } from '@shared/utils/date-utils';

type RestrictedInterval = Exclude<IntervalEnum, 3 | 4 | 5>;

const intervalDaysAmountBoundaries: { [key in RestrictedInterval]: number } = {
  [IntervalEnum.TenMinutes]: 31,
  [IntervalEnum.Hour]: 186,
};

const exceedDaysNumberErrorMessages: { [key in RestrictedInterval]: string } = {
  [IntervalEnum.TenMinutes]: '10 min interval is available for one month only',
  [IntervalEnum.Hour]: 'hour interval is available for 6 months only',
};

@Component({
  selector: 'hm-filters-panel-floor',
  templateUrl: './filters-panel-floor.component.html',
  styleUrls: ['./filters-panel-floor.component.scss']
})
export class FiltersPanelFloorComponent {
  @ViewChild('form') form!: NgForm;

  constructor(
    private readonly buildingsFacade: BuildingsFacade,
    private readonly filtersFacade: FiltersFacade,
  ) {
  }

  public readonly vm$ = combineLatest([
    this.buildingsFacade.buildingsData$,
    this.filtersFacade.getFilterFormValueByType(FilterTypeEnum.FloorUtilization)
  ]).pipe(
    map(([buildingsData, filterValue]) => ({
      buildingsData,
      filterValue
    })),
    tap((vm) => {
      this.filterFloors(vm.filterValue.building);
    })
  );
  public readonly datePickerDateFormat = datePickerDateFormat;
  public readonly today = new Date();
  public readonly defaultFormValue = defaultFloorFormValue;
  public filteredFloors: Floor[] = [];

  selectBuilding(building: Building): void {
    this.filterFloors(building);

    this.form.controls.floor.reset();
    this.form.controls.dateRange.reset();
  }

  selectFloor(): void {
    this.form.controls.interval.reset(this.defaultFormValue.interval);
    this.form.controls.dateRange.reset();
  }

  selectInterval(): void {
    this.form.controls.dateRange.reset();
  }

  selectDateRange() {
    const { dateRange, interval } = this.form.value;

    if (!dateRange) {
      return;
    }

    const [from, to] = dateRange;
    const daysDiff = getDatesDiff(from.toUTCString(), to.toUTCString(), 'days') + 1;

    if (interval.value in intervalDaysAmountBoundaries && daysDiff > intervalDaysAmountBoundaries[interval.value as RestrictedInterval]) {
      this.form.controls.dateRange.setErrors({
        maxDays: exceedDaysNumberErrorMessages[interval.value as RestrictedInterval]
      });
    }
  }

  filterFloors(building: Building | null): void {
    if (!building) {
      return;
    }

    this.filteredFloors = this.buildingsFacade.filterFloors(building.buildingId);
  }

  submit(form: NgForm): void {
    const filter = this.filtersFacade.convertFormValueToModel(FilterTypeEnum.FloorUtilization, form.value);

    this.filtersFacade.applyFilter(filter);
  }
}
