import { HttpParams } from '@angular/common/http';
import { DateRange } from '@shared/utils/date-utils';
import { FilterDto } from '@modules/filters/dtos';

export function buildFilterQueryParams(filter: FilterDto, startDate: string, endDate: string): HttpParams {
  return new HttpParams()
    .set('StartDate', startDate)
    .set('EndDate', endDate)
    .set('BuildingId', filter.buildingId)
    .set('Device', filter.device)
    .set('HoursType', filter.hoursType)
    .set('Interval', filter.interval);
}

export function encompassEntireDay(from: string, to: string): [string, string] {
  return encompassEntireDayIfConditionMet(from, to, () => true);
}

export function encompassEntireDayIfConditionMet(from: string, to: string, shouldUpdatePredicate: () => boolean): [string, string] {
  if (shouldUpdatePredicate()) {
    const { startDate, endDate } = DateRange.from(from, to, {
      start: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      end: {
        hours: 24,
        minutes: 0,
        seconds: 0
      },
    });

    return [startDate, endDate];
  }

  return [from, to];
}
