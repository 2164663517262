<ng-container *ngIf="filters$ | async as filters">
  <hm-empty-state-no-data *ngIf="!filters || filters?.length === 0; else filtersList"></hm-empty-state-no-data>

  <ng-template #filtersList>
    <ng-container *ngFor="let filter of filters; last as last">
      <hm-filters-card
        [filter]="filter"
        (selectFilter)="selectFilter($event)"
        (removeFilter)="removeFilter($event)">
      </hm-filters-card>
      <div *ngIf="!last" class="filters-card-list__line"></div>
    </ng-container>
  </ng-template>
</ng-container>
