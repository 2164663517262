import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

interface UrlsState {
  previousUrl: string | null;
  currentUrl: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  private readonly router = inject(Router);

  private readonly urlsSubject = new BehaviorSubject<UrlsState>({
    previousUrl: null,
    currentUrl: null,
  });
  public readonly urlsState$ = this.urlsSubject.asObservable();

  private readonly queryParamsByUrl: Map<string, Params> = new Map();

  constructor() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(event => this.storePreviousUrl(event as NavigationEnd)),
      tap(() => this.storeLastQueryParam())
    ).subscribe();
  }

  public getUrlsState(): UrlsState {
    return this.urlsSubject.value;
  }

  public getQueryParamsHistory(): Map<string, Params> {
    return this.queryParamsByUrl;
  }

  private storePreviousUrl(event: NavigationEnd) {
    const previousUrl = this.urlsSubject.value.currentUrl;
    const currentUrl = (event as NavigationEnd).url;

    this.urlsSubject.next({ previousUrl, currentUrl });
  }

  private storeLastQueryParam() {
    const { currentUrl } = this.getUrlsState();

    if (!currentUrl) {
      return;
    }

    const url = new URL(currentUrl, window.location.origin);
    const searchParamsObject = Array.from(url.searchParams.entries()).reduce((result, [key, value]) => {
      result[key] = value;

      return result
    }, {} as Record<string, string>);

    this.queryParamsByUrl.set(url.pathname, searchParamsObject);
  }
}
