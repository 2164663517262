import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User, UsersService } from '@core/users';
import { MsalService } from '@azure/msal-angular';
import { Role } from '@core/users/enums/role.enum';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UsersFacade {
  private readonly currentUserSubject = new BehaviorSubject<User | null>(null);
  public readonly currentUser$ = this.currentUserSubject.asObservable().pipe(
    filter((user: User | null): user is User => user !== null)
  );

  constructor(
    private readonly msalService: MsalService,
    private readonly usersService: UsersService
  ) {
  }

  loadCurrentUser(): void {
    this.usersService.fetchCurrentUser().subscribe((user: User) => this.currentUserSubject.next(user));
  }

  logout() {
    this.msalService.logout();
  }

  checkIsUserHasRole(role: Role): boolean {
    return this.currentUserSubject.value?.roles.includes(role) ?? false;
  }
}
