import { Component, forwardRef, Input } from '@angular/core';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hm-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioListComponent),
      multi: true
    }
  ]
})
export class RadioListComponent implements ControlValueAccessor {
  @Input() name!: string;
  @Input() options!: unknown[];
  @Input() optionLabelField!: string;
  @Input() optionValueField?: string;
  @Input() title!: string;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() theme: Themes = Themes.dark;
  @Input() isRequired = false;

  value: unknown;
  isDisabled = false;

  private onChange!: (value: unknown) => void;
  private onTouched!: (value: unknown) => void;

  writeValue(value: unknown) {
    this.value = value;
  }

  registerOnChange(fn: (value: unknown) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: unknown) => void) {
    this.onTouched = fn;
  }

  onValueChanged(value: unknown) {
    this.value = value;
    this.onChange(value);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
