import { Injectable } from '@angular/core';
import { HttpContextToken, HttpErrorResponse, HttpRequest } from '@angular/common/http';

export const SKIP_GLOBAL_ERROR_HANDLER = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorsService {
  private readonly whitelistedErrorCodes = [
    "DbValidation",
    "BuildingMappingAlreadyExists",
    "BuildingMappingDuplicated",
    "HeatMapBuildingMappingAlreadyExists",
    "FloorMappingAlreadyExists",
    "HeatMapFloorMappingAlreadyExists",
    "AreaMappingAlreadyExists",
    "HeatMapAreaMappingAlreadyExists",
  ]

  isInterceptorEnabledFor(request: HttpRequest<unknown>, error: HttpErrorResponse) {
    if (this.isErrorWhitelisted(error)) {
      return false;
    }

    if (request.context.get(SKIP_GLOBAL_ERROR_HANDLER)) {
      return false;
    }

    return request.url.includes('/admin');
  }

  private isErrorWhitelisted(error: HttpErrorResponse) {
    return this.whitelistedErrorCodes.includes(error.error?.ErrorCode);
  }
}
