import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadersCountSubject = new BehaviorSubject(0);

  public isLoading$ = this.loadersCountSubject.asObservable().pipe(
    map(loadersCount => loadersCount > 0),
  );

  private set loadersCount(value: number) {
    this.loadersCountSubject.next(value);
  }

  private get loadersCount(): number {
    return this.loadersCountSubject.value;
  }

  showLoader() {
    this.loadersCount++;
  }

  hideLoader() {
    if (this.loadersCount === 0) {
      return;
    }

    this.loadersCount--;
  }
}
