import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef, Injector,
  Input, Output,
  AfterViewInit, ViewChild, TemplateRef, Optional, Self, SkipSelf
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { StickerDirective } from '@usitsdasdesign/dds-ng/sticker';
import { SearchComponent as DDSSearchComponent } from '@usitsdasdesign/dds-ng/search/search/search.component';

@Component({
  selector: 'hm-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent<T extends {[key: string] : string}> implements ControlValueAccessor, AfterViewInit {
  @Input() suggestions: T[] | null = null;
  @Input() optionKey: keyof T | (keyof T)[] = 'label';
  @Input() optionTemplate!: TemplateRef<Element>;
  @Input() label = '';
  @Input() placeholder = '';

  @Output() searchTermSet = new EventEmitter<string>();

  @ViewChild('sticker') sticker!: StickerDirective;
  @ViewChild('search') search?: DDSSearchComponent;

  @Input() set isDisabled(value: boolean) {
    this._disabled = value;
  }

  get isDisabled(): boolean {
    return this._disabled;
  }

  private _disabled = false;

  @Input() set isRequired(value: boolean | string) {
    this._required = value === '' || value === true || value === 'true';
  }

  get isRequired(): boolean {
    return this._required;
  }

  private _required = false;

  constructor(private injector: Injector) {
  }

  public searchValue$ = new BehaviorSubject<string | null>(null);


  private ngControl: NgControl | null = null;

  async ngAfterViewInit() {
    await Promise.resolve();
    this.ngControl = this.injector.get(NgControl, null);
    if (this.searchValue$.getValue()) {
      this.search?.writeValue(this.searchValue$.getValue() ?? '');
    }
  }

  writeValue(value: T): void {
    if (value) {
      const currentValue = this.getCurrentValue(value);
      this.searchValue$.next(currentValue);
    }
  }

  get errorStatus(): boolean {
    return !!this.ngControl?.errors && !!this.ngControl?.touched;
  }

  private onModelChange!: (value: T | null | string) => void;
  private onModelTouched!: () => void;

  registerOnChange(fn: (value: T | null | string) => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  onTermSearch(term: string) {
    this.searchTermSet.emit(term);
    this.searchValue$.next(term);
    if (!term.length) {
      this.onModelChange(null);
    }
  }

  markAsTouched() {
    this.onModelTouched();
  }

  onOptionSelect(value: T | null) {
    this.onModelChange(value);
    const currentValue = this.getCurrentValue(value);
    this.search?.writeValue(value ? currentValue : '');
    this.sticker.hide();
  }

  onHidden() {
    if (this.search?.value) {
      return;
    }

    this.onModelChange(null);
  }

  private concatenateValues(value: T, keys: string[]): string {
    return keys
      .filter(key => Object.prototype.hasOwnProperty.call(value, key))
      .map(key => value[key])
      .join(' ');
  }

  private getCurrentValue(value: T | null): string {
    if (!value) return '';

    if (Array.isArray(this.optionKey)) {
      return this.concatenateValues(value, this.optionKey as string[]);
    } else if (typeof this.optionKey === 'string') {
      return value[this.optionKey];
    }

    return '';
  }
}
