import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { devicesList, hoursTypesList, intervalsList } from 'src/app/modules/filters/constants';
import { filter } from 'rxjs/operators';
import { Area, Building, BuildingsData, Floor } from '@modules/buildings/models';
import { BuildingsService } from '@modules/buildings/services/buildings.service';
import { BuildingsDataDto } from '@modules/buildings/dtos';

const initialBuildingsData = {
  buildings: [],
  floors: [],
  areas: [],
  areaTypes: [],
  hoursTypes: [],
  intervals: [],
  devices: [],
};

@Injectable({
  providedIn: 'root'
})
export class BuildingsFacade {
  private readonly buildingsDataSubject = new BehaviorSubject<BuildingsData>(initialBuildingsData);
  public readonly buildingsData$ = this.buildingsDataSubject.asObservable()
    .pipe(
      filter((buildingsData: BuildingsData) => buildingsData !== initialBuildingsData)
    );

  private get buildingsData(): BuildingsData {
    return this.buildingsDataSubject.value;
  }

  constructor(private readonly buildingsService: BuildingsService) {
  }

  loadBuildingsData(): void {
    this.buildingsService.fetchBuildingsData().subscribe((buildingsData: BuildingsDataDto) => {
      this.buildingsDataSubject.next({
        ...buildingsData,
        hoursTypes: hoursTypesList,
        intervals: intervalsList,
        devices: devicesList,
      } as BuildingsData);
    });
  }

  filterFloors(buildingId: number): Floor[] {
    const allFloors = [...this.buildingsData.floors];

    return allFloors.filter(floor => floor.buildingId === buildingId);
  }

  filterAreas(floorId: number, areaTypeId?: number): Observable<Area[]> {
    const rawAreas = [...this.buildingsData.areas];
    let areas = rawAreas.filter(area => area.floorId === floorId);

    if (typeof areaTypeId == 'number') {
      areas = areas.filter(area => area.areaTypeId === areaTypeId);
    }

    return of(areas as Area[]);
  }

  getModelById({ buildingId, floorId, areaId }: { buildingId?: number; floorId?: number; areaId?: number }): Building | Floor | Area | undefined {
    if (buildingId) {
      return this.buildingsData.buildings.find(building => building.buildingId === buildingId);
    } else if (floorId) {
      return this.buildingsData.floors.find(floor => floor.floorId === floorId);
    } else if (areaId) {
      return this.buildingsData.areas.find(area => area.areaId === areaId);
    }

    return undefined;
  }
}
