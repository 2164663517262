import { Optional, Provider } from '@angular/core';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';

export const FormViewProvider: Provider = {
  provide: ControlContainer,
  useFactory: formViewProviderFactory,
  deps: [
    [new Optional(), NgForm],
    [new Optional(), NgModelGroup]
  ]
};

export function formViewProviderFactory(ngForm: NgForm, ngModelGroup: NgModelGroup) {
  return ngModelGroup || ngForm || null;
}
