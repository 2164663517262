import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from '@modules/filters/models';
import { BuildingsData } from '@modules/buildings/models';
import {
  deviceLabel,
  filterTypeLabel,
  hoursTypeLabel,
  intervalLabel,
  technologyLabel
} from '@modules/filters/constants';
import { FilterTypeEnum } from '@modules/filters/enums';
import { checkFilterTypeHasArea, checkFilterTypeHasFloor } from '@modules/filters/utils';
import { datePickerDateFormat } from '@shared/constants';

@Component({
  selector: 'hm-filters-card',
  templateUrl: './filters-card.component.html',
  styleUrls: ['./filters-card.component.scss']
})
export class FiltersCardComponent {
  @Input() filter!: Filter;
  @Input() buildingsData!: BuildingsData;

  @Output() selectFilter = new EventEmitter<Filter>();
  @Output() removeFilter = new EventEmitter<Filter>();

  readonly deviceLabel = deviceLabel;
  readonly filterTypeLabel = filterTypeLabel;
  readonly hoursTypeLabel = hoursTypeLabel;
  readonly intervalLabel = intervalLabel;
  readonly technologyLabel = technologyLabel;
  readonly datePickerDateFormat = datePickerDateFormat;
  readonly filterTypeIcon: { [key: string]: string } = {
    [FilterTypeEnum.BuildingUtilization]: 'assets/icons/building.svg',
    [FilterTypeEnum.FloorUtilization]: 'assets/icons/floor-plan.svg',
    [FilterTypeEnum.AreaUtilization]: 'assets/icons/workspace.svg',
    [FilterTypeEnum.BuildingsComparison]: 'assets/icons/law-scales.svg',
    [FilterTypeEnum.NewBuildings]: 'assets/icons/buildings.svg',
  };

  get hasFloor(): boolean {
    return checkFilterTypeHasFloor(this.filter?.filterType);
  }

  get hasArea(): boolean {
    return checkFilterTypeHasArea(this.filter?.filterType);
  }

  select(): void {
    this.selectFilter.emit(this.filter);
  }

  remove(): void {
    this.removeFilter.emit(this.filter);
  }
}
