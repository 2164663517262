import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from '@shared/services/router.service';
import { ModalService } from '@core/services';

@Component({
  selector: 'hm-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  protected statusCode!: string | null;
  protected title = '';
  protected description = '';
  protected buttonLabel!: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly routerService: RouterService,
    private readonly modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    this.modalService.closeAll();

    this.statusCode = this.route.snapshot.queryParamMap.get('statusCode');

    switch (this.statusCode) {
      case '403': return this.create403ErrorPage();
      case '404': return this.create404ErrorPage();
      default: return this.createSomethingWentWrongErrorPage();
    }
  }

  navigateBack() {
    const state = this.routerService.getUrlsState();

    if (state.previousUrl) {
      return this.router.navigateByUrl(state.previousUrl);
    }

    return this.router.navigate(['..']);
  }

  private create403ErrorPage() {
    this.title = "Access denied";
    this.description = "You do not have access to this page.";
    this.buttonLabel = "";
  }

  private create404ErrorPage() {
    this.title = "Page not found";
    this.description = "We can’t find the page you are looking for. You can either return to the previous page or homepage.";
    this.buttonLabel = "Go to Homepage";
  }

  private createSomethingWentWrongErrorPage() {
    this.description = "Something went wrong";
    this.buttonLabel = "Reload page";
  }
}
