<ng-container *ngIf="currentUser$ | async as currentUser">
  <button
    type="button"
    class="profile-menu__button"
    [ddsSticker]="profileMenu"
    stickerPosition="right"
    [stickerIndent]="15"
    [stickerWidth]="240">
    <ng-container *ngTemplateOutlet="userPic"></ng-container>
  </button>

  <ng-template #userPic>
    <dds-user-pic
      width="32px"
      height="32px"
      size="md"
      [initials]="initials">
    </dds-user-pic>
  </ng-template>

  <ng-template #profileMenu>
    <dds-context-menu size="lg">
      <dds-context-menu-item customClass="profile-menu__menu-item-user">
        <ng-container *ngTemplateOutlet="userPic"></ng-container>
        <span class="dds-body">{{ currentUser.firstName }} {{ currentUser.lastName }}</span>
      </dds-context-menu-item>
      <hr>
      <dds-context-menu-item (selectedItem)="logout()">
        Log Out
      </dds-context-menu-item>
    </dds-context-menu>
  </ng-template>
</ng-container>
