import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContextMenuModule } from '@usitsdasdesign/dds-ng/context-menu';
import { CheckboxModule } from '@usitsdasdesign/dds-ng/checkbox';
import { I18nModule } from '@usitsdasdesign/dds-ng/shared/i18n';
import { HeaderModule } from '@usitsdasdesign/dds-ng/header';
import { TabsModule } from '@usitsdasdesign/dds-ng/tabs';
import { SelectModule } from '@usitsdasdesign/dds-ng/select';
import { RadioModule } from '@usitsdasdesign/dds-ng/radio';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { CardModule } from '@usitsdasdesign/dds-ng/cards';
import { DatepickerModule } from '@usitsdasdesign/dds-ng/datepicker';
import { ModalModule } from '@usitsdasdesign/dds-ng/modal';
import { SegmentedControlModule } from '@usitsdasdesign/dds-ng/segmented-control';
import { TooltipModule } from '@usitsdasdesign/dds-ng/tooltip';
import { UserPicModule } from '@usitsdasdesign/dds-ng/user-pic';
import { ProgressModule } from '@usitsdasdesign/dds-ng/progress';
import { DropdownModule } from '@usitsdasdesign/dds-ng/dropdown';
import { SearchModule } from '@usitsdasdesign/dds-ng/search';
import { BreadcrumbsModule } from '@usitsdasdesign/dds-ng/breadcrumbs';

import { SelectListComponent } from './components/select-list/select-list.component';
import { RadioListComponent } from './components/radio-list/radio-list.component';
import { EnumLabelPipe } from './pipes/enum-label.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EmptyStateNoDataComponent } from './components/empty-state-no-data/empty-state-no-data.component';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { StickerModule } from '@usitsdasdesign/dds-ng/sticker';
import { BlobImageComponent } from './components/blob-image/blob-image.component';
import { IconStickerComponent } from './components/icon-sticker/icon-sticker.component';
import { PaginationModule } from '@usitsdasdesign/dds-ng/pagination';
import { SearchComponent } from './components/search/search.component';
import { InputModule } from '@usitsdasdesign/dds-ng/input';
import { SpinnerModule } from '@usitsdasdesign/dds-ng/spinner';
import { FilterModule } from '@usitsdasdesign/dds-ng/filter';
import { HighlightModule } from '@usitsdasdesign/dds-ng/shared/highlight';
import { ToastModule } from '@usitsdasdesign/dds-ng/toast';
import { ElementSizeDirective } from '@shared/directives/element-size.directive';
import { PageSpinnerComponent } from '@shared/components/page-spinner/page-spinner.component';
import { HighlightRouterLinkDirective } from './directives/highlight-router-link.directive';
import { UploaderModule } from '@usitsdasdesign/dds-ng/uploader';
import { AutocompleteComponent } from '@shared/components/autocomplete/autocomplete.component';
import { ModalComponent } from './components/modal/modal.component';

const ddsModules = [
  ContextMenuModule,
  CheckboxModule,
  I18nModule,
  HeaderModule,
  TabsModule,
  SelectModule,
  RadioModule,
  ButtonModule,
  CardModule,
  DatepickerModule,
  ModalModule,
  SegmentedControlModule,
  TooltipModule,
  UserPicModule,
  StickerModule,
  ProgressModule,
  DropdownModule,
  PaginationModule,
  SearchModule,
  BreadcrumbsModule,
  InputModule,
  SpinnerModule,
  FilterModule,
  HighlightModule,
  ToastModule,
  UploaderModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ...ddsModules,
    ElementSizeDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ...ddsModules,
    SelectListComponent,
    RadioListComponent,
    EnumLabelPipe,
    ConfirmDialogComponent,
    EmptyStateNoDataComponent,
    TimeFromNowPipe,
    ProfileMenuComponent,
    BlobImageComponent,
    IconStickerComponent,
    SearchComponent,
    PageSpinnerComponent,
    HighlightRouterLinkDirective,
    AutocompleteComponent,
    ModalComponent,
  ],
  declarations: [
    SelectListComponent,
    RadioListComponent,
    EnumLabelPipe,
    ConfirmDialogComponent,
    EmptyStateNoDataComponent,
    TimeFromNowPipe,
    ProfileMenuComponent,
    BlobImageComponent,
    IconStickerComponent,
    SearchComponent,
    PageSpinnerComponent,
    HighlightRouterLinkDirective,
    AutocompleteComponent,
    ModalComponent
  ],
})
export class SharedModule {
}
