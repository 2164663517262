<div
  #elementSize="hmElementSize"
  #sticker="dds-sticker"
  (onHidden)="onHidden()"
  [ddsSticker]="optionsList"
  [stickerMaxHeight]="220"
  [stickerIndent]="10"
  [stickerIsDisabled]="isDisabled"
  [stickerIsOutsideClick]="true"
  hmElementSize
  stickerPosition="bottom-left"
  [ngClass]="{
    'dds-select_error': !!errorMessage
  }"
>
  <div *ngIf="label" class="dds-select__text-wrap">
    <label class="dds-select__title">
      {{ label }}
      <span *ngIf="isRequired" class="dds-required"></span>
    </label>
  </div>
  <dds-search
    #search
    (valueChanged)="onTermChanged($event)"
    [isDisabled]="isDisabled"
    [isTrimValue]="true"
    [placeholder]="placeholder"
    size="md">
  </dds-search>
  <span *ngIf="errorMessage" class="dds-select__error">{{ errorMessage }}</span>
</div>

<ng-template #optionsList>
  <div [ngStyle]="{ 'width.px': elementSize.width }" role="listbox" *ngIf="isOptionsAvailable">
    <div class="dds-context-menu" *ngIf="(options$ | async).length !== 0">
      <ng-container *ngIf="options$ | async as filteredOptions">
        <button
          (click)="onOptionSelected(option)"
          *ngFor="let option of filteredOptions"
          class="dds-context-menu-item"
          role="option">
        <span class="dds-context-menu-item__text">
          <ng-container
            *ngTemplateOutlet="optionTemplate; context: { $implicit: option, searchTerm: term }">
          </ng-container>
        </span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
