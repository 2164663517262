import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'hm-blob-image',
  templateUrl: './blob-image.component.html',
  styleUrls: ['./blob-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlobImageComponent implements OnChanges {
  @Input() blob!: Blob | null;
  @Input() size!: { width: number; height: number };

  @HostBinding('style.width') get width(): string {
    return `${this.size?.width ?? 0}px`;
  }

  @HostBinding('style.height') get height(): string {
    return `${this.size?.height ?? 0}px`;
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
  }

  ngOnChanges(): void {
    this.clear();

    if (this.size && this.blob) {
      this.render(this.blob);
    }
  }

  private render(blob: Blob): void {
    const img = new Image(this.size.width, this.size.height);
    img.src = URL.createObjectURL(blob);

    this.renderer.appendChild(this.elementRef.nativeElement, img);
  }

  private clear(): void {
    for (const child of Array.from(this.elementRef.nativeElement.childNodes)) {
      this.renderer.removeChild(this.elementRef.nativeElement, child);
    }
  }
}
