import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@core/http';
import { User } from '@core/users';

@Injectable({ providedIn: 'root' })
export class UsersService {

  constructor(private readonly http: ApiService) {}

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>('/users/current');
  }
}
