import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import {
  FiltersCardComponent,
  FiltersCardListComponent,
  FiltersPanelHeaderComponent,
  FiltersPanelBuildingComponent,
  FiltersPanelFloorComponent,
} from './components';
import { FiltersPanelActionsComponent } from './components/filters-panel-actions/filters-panel-actions.component';
import { FiltersPageHeaderComponent } from './components/filters-page-header/filters-page-header.component';
import { RemoveRecentSelectionDialogComponent } from './components/remove-recent-selection-dialog/remove-recent-selection-dialog.component';
import { DateRangeIntervalDirective } from './directives/date-range-interval.directive';


@NgModule({
  imports: [
    SharedModule,
    NgOptimizedImage
  ],
  exports: [
    FiltersCardComponent,
    FiltersCardListComponent,
    FiltersPanelHeaderComponent,
    FiltersPanelBuildingComponent,
    FiltersPanelFloorComponent,
    FiltersPageHeaderComponent,
  ],
  declarations: [
    FiltersCardComponent,
    FiltersCardListComponent,
    FiltersPanelHeaderComponent,
    FiltersPanelBuildingComponent,
    FiltersPanelFloorComponent,
    FiltersPanelActionsComponent,
    FiltersPageHeaderComponent,
    RemoveRecentSelectionDialogComponent,
    DateRangeIntervalDirective,
  ]
})
export class FiltersModule { }
