import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'hm-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectListComponent),
      multi: true
    }
  ]
})
export class SelectListComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() options!: unknown[] | null;
  @Input() optionLabelField!: string;
  @Input() optionValueField?: string;
  @Input() isRequired = false;

  value: unknown;
  isDisabled = false;

  private onChange!: (value: unknown) => void;
  private onTouched!: (value: unknown) => void;

  writeValue(value: unknown) {
    this.value = value;
  }

  registerOnChange(fn: (value: unknown) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: unknown) => void) {
    this.onTouched = fn;
  }

  onValueChanged(value: unknown) {
    this.value = value;
    this.onChange(value);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
