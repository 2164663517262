import { EnumValue } from '../models';
import { DeviceEnum } from '../enums';
import { deviceLabel } from './device-label';

export const deviceMobileLaptop = {
  value: DeviceEnum.MobileLaptop,
  label: deviceLabel[DeviceEnum.MobileLaptop],
};

export const devicesList: EnumValue[] = [
  deviceMobileLaptop,
  {
    value: DeviceEnum.LaptopMobile,
    label: deviceLabel[DeviceEnum.LaptopMobile],
  },
  {
    value: DeviceEnum.Mobile,
    label: deviceLabel[DeviceEnum.Mobile],
  },
  {
    value: DeviceEnum.Laptop,
    label: deviceLabel[DeviceEnum.Laptop],
  },
];
