import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumLabel'
})
export class EnumLabelPipe implements PipeTransform {

  transform<T>(value: T, labelsMap: { [key: string]: string }): string {
    return labelsMap[value as string] ?? value;
  }

}
