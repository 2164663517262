import { Component, inject } from '@angular/core';
import { LoaderService } from '@core/services';

@Component({
  selector: 'hm-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss'],
})
export class PageSpinnerComponent {
  protected isLoading$ = inject(LoaderService).isLoading$;
}
