import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { EnumValue, Filter, FilterFormModel } from '@modules/filters/models';
import { FilterDto } from '@modules/filters/dtos';
import { FiltersService } from '@modules/filters/services';
import { BuildingsFacade } from '@modules/buildings/facades/buildings-facade.service';
import { FilterTypeEnum, TechnologyEnum } from '@modules/filters/enums';
import {
  defaultBuildingFormValue,
  defaultFloorFormValue,
  devicesList,
  hoursTypesList,
  intervalsList,
  technologiesList
} from '@modules/filters/constants';
import { formatToServerDateString, parseUtc } from '@shared/utils/date-utils';
import { Building, BuildingsData, Floor } from '@modules/buildings/models';
import { urlByFilterType } from '@modules/filters/constants/url-by-filter-type';

const defaultFiltersValues: { [key: string]: FilterFormModel } = {
  [FilterTypeEnum.BuildingUtilization]: defaultBuildingFormValue,
  [FilterTypeEnum.FloorUtilization]: defaultFloorFormValue
};

@Injectable({ providedIn: 'root' })
export class FiltersFacade {
  private readonly rawFiltersSubject = new ReplaySubject<FilterDto[]>(1);
  private readonly filtersByTypeSubject = new BehaviorSubject<{ [key: string]: Filter }>({});

  public readonly rawFilters$ = this.rawFiltersSubject.asObservable();
  public readonly filters$ = combineLatest([this.rawFilters$, this.buildingsFacade.buildingsData$])
    .pipe(
      map(([filters, buildingsData]) => {
        return filters.map(filter => {
          return {
            ...filter,
            building: buildingsData.buildings.find(b => b.buildingId === filter.buildingId),
            buildings: buildingsData.buildings.filter(b => filter.buildingIds?.includes(b.buildingId)),
            floor: buildingsData.floors.find(f => f.floorId === filter.floorId),
            areas: buildingsData.areas.filter(a => filter.areaIds?.includes(a.areaId)),
            areaTypes: buildingsData.areaTypes.filter(a => filter.areaTypeIds?.includes(a.areaTypeId)),
          } as Filter;
        });
      }),
    );
  public readonly filtersByType$ = this.filtersByTypeSubject.asObservable();

  constructor(
    private readonly filtersService: FiltersService,
    private readonly buildingsFacade: BuildingsFacade,
    private readonly router: Router,
  ) {
  }

  loadFilters(): void {
    this.fetchFilters().subscribe();
  }

  removeFilter(filter: Filter) {
    if (!filter.filterId) {
      return;
    }

    this.filtersService.removeFilter(filter.filterId).subscribe(() => this.loadFilters());
  }

  applyFilter(filter: Filter) {
    return this.saveFilter(filter)
      .pipe(
        first(),
        tap((savedFilter) => this.setFilter({...filter, filterId: savedFilter.filterId}))
      )
      .subscribe(() => {
        const url = urlByFilterType[filter.filterType];
        this.router.navigate([url]);
      });
  }

  convertFormValueToModel(filterType: FilterTypeEnum, formModel: FilterFormModel): Filter {
    const [startDate, endDate] = formModel.dateRange as [Date, Date];

    return {
      filterType,
      building: formModel.building as Building,
      buildingId: formModel.building?.buildingId as number,
      floor: formModel.floor as Floor,
      floorId: formModel.floor?.floorId as number,
      startDate: formatToServerDateString(startDate),
      endDate: formatToServerDateString(endDate),
      device: formModel.device.value,
      hoursType: formModel.hoursType.value,
      interval: formModel.interval.value,
      technology: formModel.technology?.value,
    } as Filter;
  }

  convertModelToFormValue(filter: Filter): FilterFormModel {
    return {
      ...filter,
      dateRange: filter.startDate && filter.endDate ? [parseUtc(filter.startDate), parseUtc(filter.endDate)] : null,
      device: devicesList.find(enumValue => enumValue.value === filter.device) as EnumValue,
      hoursType: hoursTypesList.find(enumValue => enumValue.value === filter.hoursType) as EnumValue,
      interval: intervalsList.find(enumValue => enumValue.value === filter.interval) as EnumValue,
      technology: technologiesList.find(enumValue => enumValue.value === filter.technology) as EnumValue,
    };
  }

  getFilterByType(filterType: FilterTypeEnum): Observable<Filter | null> {
    return this.filtersByType$.pipe(
      map(filtersByType => filterType in filtersByType ? filtersByType[filterType] : null),
    );
  }

  getFilterFormValueByType(filterType: FilterTypeEnum): Observable<FilterFormModel> {
    const currentFilter$ = this.getFilterByType(filterType);

    return combineLatest([currentFilter$, this.buildingsFacade.buildingsData$]).pipe(
      map(([currentFilter, buildingsData]: [Filter | null, BuildingsData]) => {
        if (!currentFilter) {
          return [defaultFiltersValues[filterType], buildingsData] as [FilterFormModel, BuildingsData];
        }

        return [this.convertModelToFormValue(currentFilter), buildingsData] as [FilterFormModel, BuildingsData];
      }),
      map(([formValue, buildingsData]: [FilterFormModel, BuildingsData]) => {
        return {
          ...formValue,
          building: buildingsData.buildings.find(building => building.buildingId === formValue.building?.buildingId) ?? null,
          floor: buildingsData.floors.find(floor => floor.floorId === formValue.floor?.floorId) ?? null,
        }
      }),
    );
  }

  trySetLatestAvailableFilter(filterType: FilterTypeEnum) {
    this.fetchFilters()
      .pipe(
        switchMap(() => this.filters$),
        first(),
        map(filters => filters.find(filter => filter.filterType === filterType)),
        tap(filter => {
          if (filter) {
            this.setFilter(filter);
          } else {
            this.router.navigate(['/']);
          }
        })
      )
      .subscribe();
  }

  private fetchFilters() {
    return this.filtersService.fetchFilters()
      .pipe(
        tap(rawFilters => this.rawFiltersSubject.next(rawFilters))
      );
  }

  private saveFilter(filter: Filter) {
    return this.filtersService.saveFilter({ ...filter, technology: TechnologyEnum.WiFi, });
  }

  private setFilter(filter: Filter) {
    this.filtersByTypeSubject.next({
      ...this.filtersByTypeSubject.value,
      [filter.filterType]: filter,
    });
  }
}
