import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { StickerDirective } from '@usitsdasdesign/dds-ng/sticker';
import { SearchComponent as DDSSearchComponent } from '@usitsdasdesign/dds-ng/search';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type SearchOption = {
  label: string;
  value: unknown;
}

@Component({
  selector: 'hm-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    }
  ]
})
export class SearchComponent implements AfterViewInit, OnChanges, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() options!: SearchOption[];
  @Input() optionTemplate!: TemplateRef<any>;
  @Input() minTermLength = 3;

  @Input() label = '';
  @Input() isRequired = false;
  @Input() errorMessage = '';

  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  @Input() optionLabelField: 'label' = 'label';

  @ViewChild('sticker') sticker!: StickerDirective;
  @ViewChild('search') search?: DDSSearchComponent;

  @Output() optionSelect = new EventEmitter<SearchOption | null>();

  private readonly termSubject = new BehaviorSubject('');
  private readonly valueSubject = new BehaviorSubject<SearchOption | null>(null)
  private readonly optionsChange$ = new BehaviorSubject<SearchOption[]>(this.options);


  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && this.options.length) {
        this.optionsChange$.next(this.options);        
    }
  }

  protected readonly options$ = combineLatest([
    this.valueSubject.asObservable(),
    this.termSubject.asObservable(),
    this.optionsChange$.asObservable(),
  ]).pipe(
    map(([value, term, options]) => {      
      this.sticker.updPosition()
      if (this.touched && value && !this.search?.value) {
        return options;
      }

      const predicates = [
        (options: SearchOption[]) => this.filterByTerm(options, term),
        (options: SearchOption[]) => this.filterByValue(options, value),
      ];

      return predicates.reduce((options, predicate) => predicate(options), options);
    }),
  );

  protected isDisabled = false;
  protected touched = false;
  private onModelChange!: (value: SearchOption | null) => void;
  private onModelTouched!: () => void;

  set value(value: SearchOption | null) {
    this.valueSubject.next(value);
  }

  get value(): SearchOption | null {
    return this.valueSubject.value;
  }

  get isOptionsAvailable(): boolean {    
    return this.optionsChange$.value?.length > 0;
  }

  async ngAfterViewInit(): Promise<void> {
    await Promise.resolve();
    this.search?.writeValue(this.value?.[this.optionLabelField] ?? '');
  }

  writeValue(value: SearchOption | null) {
    this.value = value;
    this.search?.writeValue(value?.[this.optionLabelField] ?? '');
  }

  registerOnChange(fn: (value: SearchOption | null) => void) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onModelTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onModelTouched();
      this.touched = true;
    }
  }

  onOptionSelected(value: SearchOption | null) {
    this.markAsTouched();

    this.value = value;
    this.onModelChange(value);
    this.search?.writeValue(value?.[this.optionLabelField] ?? '');

    this.sticker.hide();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.search?.setDisabledState(isDisabled);
  }

  onTermChanged(term: string) {
    this.markAsTouched();
    this.termSubject.next(term.trim());
  }

  onHidden() {
    if (this.search?.value) {
      return;
    }

    this.value = null;
    this.onModelChange(null);
  }

  private filterByTerm(options: SearchOption[], term: string) {
    if (term === '' || term.length < this.minTermLength) {
      return [...options];
    }

    return options.filter(option => option[this.optionLabelField].toLocaleLowerCase().includes(term.toLowerCase()));
  }

  private filterByValue(options: SearchOption[], value: SearchOption | null) {
    if (value) {
      return options.filter(option => option[this.optionLabelField] !== value[this.optionLabelField]);
    }

    return [...options];
  }
}
