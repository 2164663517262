<dds-button
  theme="green"
  size="md"
  kind="secondary"
  (click)="reset()">
  Reset
</dds-button>
<dds-button
  theme="green"
  size="md"
  kind="secondary"
  (click)="close()">
  Cancel
</dds-button>
<dds-button type="submit" [isDisabled]="!form.valid" (click)="close()">
  Apply
</dds-button>
