import { Pipe, PipeTransform } from '@angular/core';
import { getTimeFromNow } from '@shared/utils/date-utils';

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {

  transform(value: string): string {
    return getTimeFromNow(value);
  }
}
