import { Component } from '@angular/core';

import { ModalService } from '@core/services';
import { FilterTypeEnum } from '@modules/filters/enums';
import { FiltersPanelBuildingComponent, FiltersPanelFloorComponent, } from '@modules/filters/components';
import { Role } from '@core/users/enums/role.enum';

const filterPanel = {
  [FilterTypeEnum.BuildingUtilization]: FiltersPanelBuildingComponent,
  [FilterTypeEnum.FloorUtilization]: FiltersPanelFloorComponent,
};

type FilterPanelKey = keyof typeof filterPanel;

@Component({
  selector: 'hm-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  protected readonly filterPanelKey = FilterTypeEnum;
  protected readonly role = Role;

  constructor(
    private readonly modalService: ModalService
  ) {
  }

  openFilterPanel(filterType: FilterPanelKey): void {
    const component = filterPanel[filterType];

    if (!component) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.modalService.open(component);
  }
}
