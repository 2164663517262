import { Component, Input } from '@angular/core';
import { ModalService } from '@core/services';

@Component({
  selector: 'hm-filters-panel-header',
  templateUrl: './filters-panel-header.component.html',
  styleUrls: ['./filters-panel-header.component.scss']
})
export class FiltersPanelHeaderComponent {
  @Input() headerTitle!: string;

  constructor(private readonly modalService: ModalService) {
  }

  close(): void {
    this.modalService.close();
  }
}
