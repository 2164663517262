<dds-select
  stickerMaxHeight="256"
  [label]="label"
  [placeholder]="placeholder"
  [size]="'md'"
  [isDisabled]="isDisabled"
  [isRequired]="isRequired"
  stickerCustomClass="select-list"
  [value]="value"
  (valueChanged)="onValueChanged($event)">
  <ng-container *ngIf="options">
    <dds-select-item
      *ngFor="let option of options"
      [heading]="option[optionLabelField]"
      [value]="optionValueField ? option[optionValueField] : option">
    </dds-select-item>
  </ng-container>
</dds-select>
