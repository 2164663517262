import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@core/services';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private readonly apiRoot = this.environmentService.get('apiRoot') as string;
  private readonly apiVersion = 'v1';

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
  ) {
  }

  private get apiURL(): string {
    return `${this.apiRoot}/${this.apiVersion}`;
  }

  public get<T>(url: string, params?: HttpParams, context?: HttpContext): Observable<T> {
    return this.httpClient.get<T>(`${this.apiURL}${url}`, { params, observe: 'response', context })
      .pipe(
        map((response: HttpResponse<T>) => response.body as T),
      );
  }

  public getBlob(url: string, params?: HttpParams): Observable<Blob> {
    return this.httpClient.get(`${this.apiURL}${url}`, { params, responseType: 'blob' });
  }

  public post<T>(url: string, body?: unknown): Observable<T> {
    return this.httpClient.post<T>(`${this.apiURL}${url}`, body, { observe: 'response' })
      .pipe(
        map((response: HttpResponse<T>) => response.body as T),
      );
  }

  public postBlob(url: string, body?: unknown, { context }: Partial<{ context: HttpContext }> = {}) {
    return this.httpClient.post(`${this.apiURL}${url}`, body, { observe: 'response', responseType: 'blob', context });
  }

  public put<T>(url: string, body?: unknown): Observable<T> {
    return this.httpClient.put<T>(`${this.apiURL}${url}`, body, { observe: 'response' })
      .pipe(
        map((response: HttpResponse<T>) => response.body as T),
      );
  }

  public delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiURL}${url}`, { observe: 'response' })
      .pipe(
        map((response: HttpResponse<T>) => response.body as T),
      );
  }
}
