<span class="dds-icon dds-icon_info"></span>

<div class="legend-container">
  <div class="legend">
    <section class="legend__section">
      <span class="dds-body-semibold">Levels</span>
      <ul class="legend__list">
        <li class="legend__list-item">
          <span class="dot" style="background-color: #2C5234"></span>
          <span class="dds-label">PPMD</span>
        </li>
        <li class="legend__list-item">
          <span class="dot" style="background-color: #86BC25"></span>
          <span class="dds-label">Senior manager</span>
        </li>
        <li class="legend__list-item">
          <span class="dot" style="background-color: #00A3E0"></span>
          <span class="dds-label">Manager</span>
        </li>
        <li class="legend__list-item">
          <span class="dot" style="background-color: #C4D600"></span>
          <span class="dds-label">Staff</span>
        </li>
        <li class="legend__list-item">
          <span class="dot" style="background-color: #00ABAB"></span>
          <span class="dds-label">N/A</span>
        </li>
      </ul>
    </section>
  </div>
</div>
