import { FilterFormModel } from '@modules/filters/models';
import { intervalDay, intervalTenMinutes } from '@modules/filters/constants/intervals-list';
import { hoursTypeWorkingHours } from '@modules/filters/constants/hours-types-list';
import { deviceMobileLaptop } from '@modules/filters/constants/devices-list';

export const defaultBuildingFormValue: FilterFormModel = {
  building: null,
  dateRange: null,
  hoursType: hoursTypeWorkingHours,
  interval: intervalDay,
  device: deviceMobileLaptop,
};

export const defaultFloorFormValue: FilterFormModel = {
  building: null,
  floor: null,
  dateRange: null,
  hoursType: hoursTypeWorkingHours,
  interval: intervalTenMinutes,
  device: deviceMobileLaptop,
};
