import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { EnvironmentService } from '@core/services';

export function msalInstanceFactory(env: EnvironmentService): IPublicClientApplication {
  const clientId = env.get('clientId') as string;
  const authority = `${env.get('authority')}/${env.get('tenantId')}` ;
  const redirectUri = env.get('redirectUri') as string;

  return new PublicClientApplication({
    auth: {
      clientId,
      authority,
      redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel, message) => {
          console.log(`[ MSAL ] ${message}`);
        },
        piiLoggingEnabled: false,
        logLevel: LogLevel.Error,
      }
    }
  });
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export function msalInterceptorConfigFactory(env: EnvironmentService): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [`${env.get('apiRoot')}/*`, [`${env.get('redirectUri')}/user_impersonation`]]
    ]),
  };
}
