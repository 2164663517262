import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DestroyNotifierDirective } from '@shared/directives/destroy-notifier.directive';
import { UsersFacade } from '@core/users';
import { Role } from '@core/users/enums/role.enum';

@Directive({
  selector: '[hmIfRole]',
  hostDirectives: [
    DestroyNotifierDirective
  ]
})
export class IfRoleDirective implements OnInit, OnChanges {
  @Input() hmIfRole!: Role | Role[];

  private isRendered = false;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly usersFacade: UsersFacade,
    private readonly destroyNotifier: DestroyNotifierDirective,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.destroyNotifier.sink = this.usersFacade.currentUser$.subscribe(() => {
      this.renderIfHasAccess();
      this.changeDetectorRef.markForCheck();
    })
  }

  ngOnChanges(): void {
    this.renderIfHasAccess();
  }

  private renderIfHasAccess() {
    const isUserAllowedToViewContent = Array.isArray(this.hmIfRole)
      ? this.hmIfRole.some(role => this.usersFacade.checkIsUserHasRole(role))
      : this.usersFacade.checkIsUserHasRole(this.hmIfRole)

    if (isUserAllowedToViewContent && !this.isRendered) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.isRendered = true;
    }

    if (!isUserAllowedToViewContent && this.isRendered) {
      this.viewContainerRef.clear();
      this.isRendered = false;
    }
  }
}
