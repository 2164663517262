import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private readonly window: CustomWindow;
  private readonly env: AppEnv;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView as CustomWindow;
    // eslint-disable-next-line no-underscore-dangle
    this.env = this.window.__env__;
  }

  public get(key: keyof AppEnv) {
    if (key in this.env) {
      return this.env[key];
    }

    return undefined;
  }
}

type DocumentDefaultView = (Window & typeof globalThis) | null;
// eslint-disable-next-line @typescript-eslint/naming-convention
type CustomWindow = { __env__: AppEnv } & DocumentDefaultView;

export interface AppEnv {
  apiRoot: string;
  clientId: string;
  tenantId: string;
  authority: string;
  redirectUri: string;
  enableProdMode?: boolean;
}

