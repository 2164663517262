import { Component, inject } from '@angular/core';
import { ModalService } from '@core/services';

@Component({
  selector: 'hm-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  modalService = inject(ModalService)

  close(): void {
    this.modalService.close();
  }
}
