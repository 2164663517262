import { Component, inject, OnInit } from '@angular/core';
import { UsersFacade } from '@core/users';
import { BuildingsFacade } from '@modules/buildings/facades/buildings-facade.service';

@Component({
  selector: 'hm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly usersFacade = inject(UsersFacade);
  private readonly buildingsFacade = inject(BuildingsFacade);

  ngOnInit(): void {
    this.usersFacade.loadCurrentUser();
    this.buildingsFacade.loadBuildingsData();
  }
}
