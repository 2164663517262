<div
  #elementSize="hmElementSize"
  #sticker="dds-sticker"
  (onHidden)="onHidden()"
  (click)="markAsTouched()"
  [ddsSticker]="dropdownMenu"
  [ngClass]="{
    'dds-select_error': errorStatus
  }"
  [stickerIsDisabled]="!!isDisabled"
  hmElementSize
  stickerPosition="bottom-left"
>

  <div *ngIf="label" class="dds-select__text-wrap">
    <label class="dds-select__title">{{ label }}
      <span *ngIf="isRequired" class="dds-required"></span>
    </label>
  </div>

  <dds-search
    #search
    (valueChanged)="onTermSearch($event)"
    [isDisabled]="!!isDisabled"
    isTrimValue="true"
    [placeholder]="placeholder"
  >
  </dds-search>

</div>

<ng-template #dropdownMenu>
  <div
    *ngIf="!!suggestions"
    [ngStyle]="{ 'width.px': elementSize.width, 'height.px': 220 }" role="listbox">
    <div class="dds-context-menu">
      <ng-container *ngIf="suggestions.length !== 0; else noResult">
        <button
          (click)="onOptionSelect(option)"
          *ngFor="let option of suggestions"
          class="dds-context-menu-item"
          role="option">
          <span class="dds-context-menu-item__text">
            <ng-container
                        *ngTemplateOutlet="optionTemplate; context: { option: option }">
            </ng-container>
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noResult>
  <button
    class="dds-context-menu-item"
    disabled
    role="option">
    <span class="dds-context-menu-item__text">
      No result found
    </span>
  </button>
</ng-template>

