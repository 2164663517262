<hm-confirm-dialog dialogTitle="Delete recent selection">
  <div class="body">
    <span>Are you sure you want to delete</span>
    <b> {{ filter.filterType | enumLabel:filterTypeLabel }} selection</b>
    <span> from {{ filter.updatedAt | date:datePickerDateFormat }}?</span>
  </div>

  <div class="footer">
    <dds-button
      kind="secondary"
      width="fixed"
      (clicked)="close()">
      Cancel
    </dds-button>
    <dds-button
      theme="danger"
      width="fixed"
      (click)="remove()">
      Delete
    </dds-button>
  </div>
</hm-confirm-dialog>
