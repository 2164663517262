import { WeekDay } from '../enums';

export const dayLabelsShort: { [p: number]: string } = Object.entries(WeekDay).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {});

export const dayLabelsLong: { [p: number]: string } = {
  [WeekDay.Sun]: 'Sunday',
  [WeekDay.Mon]: 'Monday',
  [WeekDay.Tue]: 'Tuesday',
  [WeekDay.Wed]: 'Wednesday',
  [WeekDay.Thu]: 'Thursday',
  [WeekDay.Fri]: 'Friday',
  [WeekDay.Sat]: 'Saturday',
};
