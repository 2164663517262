import { EnumValue } from '../models';
import { HoursTypeEnum } from '../enums';
import { hoursTypeLabel } from './hours-type-label';

export const hoursTypeWorkingHours = {
  value: HoursTypeEnum.WorkingHours,
  label: hoursTypeLabel[HoursTypeEnum.WorkingHours],
};

export const hoursTypesList: EnumValue[] = [
  hoursTypeWorkingHours,
  {
    value: HoursTypeEnum.PeakHours,
    label: hoursTypeLabel[HoursTypeEnum.PeakHours],
  },
  {
    value: HoursTypeEnum.AfterHours,
    label: hoursTypeLabel[HoursTypeEnum.AfterHours],
  },
  {
    value: HoursTypeEnum.AllDay,
    label: hoursTypeLabel[HoursTypeEnum.AllDay],
  },
];
