import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalErrorsService } from '@core/http/global-errors.service';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly globalErrorsService: GlobalErrorsService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!this.globalErrorsService.isInterceptorEnabledFor(request, error)) {
            return throwError(error)
          }

          console.error(error);

          this.router.navigate(['/error'], {
            queryParams: {
              statusCode: error.status
            }
          });

          return EMPTY;
        })
      );
  }
}
