<section>
  <span class="dds-h4">{{ label }}</span>
</section>

<section class="filters-page-header__filter-list dds-body">
  <div class="filters-page-header__filter">
    <span class="filters-page-header__filter-name">Office:</span>
    <span>{{ filter.building.name }}</span>
  </div>
  <div *ngIf="filter.filterType > 0" class="filters-page-header__filter">
    <span class="filters-page-header__filter-name">Floor:</span>
    <span>{{ filter.floor?.name }}</span>
  </div>
  <div class="filters-page-header__filter">
    <span class="filters-page-header__filter-name">Date range:</span>
    <span>{{ filter.startDate | date:datePickerDateFormat }} - {{ filter.endDate | date:datePickerDateFormat }}</span>
  </div>
  <div class="filters-page-header__filter">
    <span class="filters-page-header__filter-name">Hours type:</span>
    <span>{{ filter.hoursType | enumLabel:hoursTypeLabel }}</span>
  </div>
  <div *ngIf="filter.filterType > 0" class="filters-page-header__filter">
    <span class="filters-page-header__filter-name">Interval:</span>
    <span>{{ filter.interval | enumLabel:intervalLabel }}</span>
  </div>
  <div class="filters-page-header__filter">
    <span class="filters-page-header__filter-name">Device:</span>
    <span>{{ filter.device | enumLabel:deviceLabel }}</span>
  </div>
</section>
