import { Component } from '@angular/core';

@Component({
  selector: 'hm-empty-state-no-data',
  templateUrl: './empty-state-no-data.component.html',
  styleUrls: ['./empty-state-no-data.component.scss']
})
export class EmptyStateNoDataComponent {

}
