import { Component, Input } from '@angular/core';
import { deviceLabel, hoursTypeLabel, intervalLabel } from '@modules/filters/constants';
import { datePickerDateFormat } from '@shared/constants';
import { Filter } from '@modules/filters/models';

@Component({
  selector: 'hm-filters-page-header',
  templateUrl: './filters-page-header.component.html',
  styleUrls: ['./filters-page-header.component.scss']
})
export class FiltersPageHeaderComponent {
  @Input() label!: string;
  @Input() filter!: Filter;

  public readonly datePickerDateFormat = datePickerDateFormat;
  public readonly hoursTypeLabel = hoursTypeLabel;
  public readonly intervalLabel = intervalLabel;
  public readonly deviceLabel = deviceLabel;
}
