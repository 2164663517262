import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormViewProvider } from '@shared/providers/form-view.provider';
import { ModalService } from '@core/services';

@Component({
  selector: 'hm-filters-panel-actions',
  templateUrl: './filters-panel-actions.component.html',
  styleUrls: ['./filters-panel-actions.component.scss'],
  viewProviders: [FormViewProvider],
})
export class FiltersPanelActionsComponent {
  @Input() defaultFormValue!: object;

  constructor(
    protected readonly form: NgForm,
    private readonly modalService: ModalService,
  ) {
  }

  reset(): void {
    this.form.reset({ ...this.defaultFormValue });
  }

  close(): void {
    this.modalService.close();
  }
}
