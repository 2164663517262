<div>
  <img [ngSrc]="filterTypeIcon[filter.filterType]" alt="Filter icon" height="32" width="32">
</div>
<div class="filters-card__content" (click)="select()">
  <div class="dds-label filters-card__viewed-date">{{ filter.updatedAt | timeFromNow }}</div>
  <div class="dds-body-semibold">{{ filter.filterType | enumLabel: filterTypeLabel }}</div>
  <div class="filters-card__filters">
    <ng-container *ngIf="filter.buildings">
      <ng-container *ngFor="let building of filter.buildings">
        <ng-container *ngTemplateOutlet="filterBuilding; context: {$implicit: building}"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="filter.building">
      <ng-container *ngTemplateOutlet="filterBuilding; context: {$implicit: filter.building}"></ng-container>
    </ng-container>

    <div *ngIf="hasFloor" class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Floor:</span>
      <span class="dds-label">{{ filter.floor?.name }}</span>
    </div>
    <div *ngIf="hasArea" class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Space type:</span>
      <span class="dds-label">Conference rooms</span>
    </div>
    <div class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Date range:</span>
      <span class="dds-label">{{ filter.startDate | date: datePickerDateFormat }} - {{ filter.endDate | date: datePickerDateFormat }}</span>
    </div>
    <div class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Hours type:</span>
      <span class="dds-label">{{ filter.hoursType | enumLabel: hoursTypeLabel }}</span>
    </div>
    <div class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Interval:</span>
      <span class="dds-label">{{ filter.interval | enumLabel: intervalLabel }}</span>
    </div>
    <div class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Technology:</span>
      <span class="dds-label">{{ filter.technology | enumLabel: technologyLabel }}</span>
    </div>
    <div class="filters-card__filter">
      <span class="dds-label filters-card__filter-label">Device:</span>
      <span class="dds-label">{{ filter.device | enumLabel: deviceLabel }}</span>
    </div>
  </div>
</div>
<div>
  <dds-button
    theme="dark"
    kind="silent"
    size="md"
    width="fixed"
    icon="dds-icon_delete"
    role="button"
    ddsTooltip="Delete"
    tooltipInvokeType="hover"
    tooltipSize="md"
    tooltipPosition="left-top"
    (click)="remove()">
  </dds-button>
</div>

<ng-template #filterBuilding let-building>
  <div class="filters-card__filter">
    <span class="dds-label filters-card__filter-label">Office:</span>
    <span class="dds-label">{{ building.name }}</span>
  </div>
</ng-template>
